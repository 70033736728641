/**
 * @function urlConfig
 * key params is for url type
 * params is for parameters which are passed for the api calls
 */
const urlConfig = (key, params) => {
  const devHost = "https://testboapitech.co.in";
  const qaHost = "https://testboapitech.co.in";
  const prodHost = "https://testboapitech.co.in";

  // const devHost = "https://www.api.solx.ws";
  // const qaHost = "https://www.api.solx.ws";
  // const prodHost = "https://www.api.solx.ws";

  // const devHost = "http://localhost:4000";
  // const qaHost = "http://localhost:4000";
  // const prodHost = "http://localhost:4000";
  /**
   * @var urlsDEV for developement urls
   */
  let urlsDEV = {
    loginUrl: `${devHost}/user/login`,
    userDetailsUrl: `${devHost}/user`,
    userAll: `${devHost}/admin/user/all?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userApprovalList: `${devHost}/admin/user/list/approval?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userApproval: `${devHost}/admin/user/toggle/approve`,
    userBlock: `${devHost}/admin/user/toggle/block`,
    userViewUrl: `${devHost}/admin/user/profile/detailed-view?userId=${params.id}`,
    userReports: `${devHost}/admin/user/reported/list?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    createCategoryUrl: `${devHost}/product/category/create`,
    categoryListingUrl: `${devHost}/product/category/main/get`,
    subcategoryListingUrl: `${devHost}/product/category/sub/get?parentId=${params.categoryId}`,
    updateCategoryUrl: `${devHost}/product/category/sub/update`,
    productListingUrl: `${devHost}/admin/product/list?type=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    productApprovalUrl: `${devHost}/admin/product/toggle-approval`,
    productModificationUrl: `${devHost}/admin/product/modification/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    interestListUrl: `${devHost}/admin/product/interest/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&type=${params.type}`,
    bidListUrl: `${devHost}/admin/product/bid/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}`,
    orderListUrl: `${devHost}/admin/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}&searchTerm=${params.searchTerm}`,
    transactionListUrl: `${devHost}/admin/transaction/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    transactionListUrlUpdated: `${devHost}/admin/transaction/list-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    approveOrRejectOrderUrl: `${devHost}/admin/order/toggle-approval`,
    updateShipmentStatus: `${devHost}/shipment/create-status`,
    getShipmentStatus: `${devHost}/shipment/get-status?perPage=${params.perPage}&page=${params.page}&orderId=${params.orderId}`,
    acceptCashUrl: `${devHost}/admin/transaction/accept-cash/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}`,
    getAllConfig: `${devHost}/admin/app-config/get-all-config`,
    setConfig: `${devHost}/admin/app-config/set-config`,
    approveTransaction: `${devHost}/admin/transaction/toggle-approve`,
    pickupListUrl: `${devHost}/admin/order/pickup/list`,
    deliveryListUrl: `${devHost}/admin/order/delivery/list`,
    settlementStatusUpdateUrl: `${devHost}/admin/transaction/update-settlement-status`,
    approveOrRejectModificationUrl: `${devHost}/admin/product/toggle-modification-approval`,
    userRegistrationTransactionUrl: `${devHost}/admin/transaction/charges/user-registration?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrl: `${devHost}/admin/transaction/charges/product-listing?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryChargeTransactionUrl: `${devHost}/admin/transaction/charges/delivery?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryChargeTransactionUrlUpdated: `${devHost}/admin/transaction/charges/delivery-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    userDetailedListUrl: `${devHost}/admin/user/detailed/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}`,
    userDetailedViewUrl: `${devHost}/admin/user/detailed-data?userId=${params.userId}`,
    productDetailedViewUrl: `${devHost}/admin/product/detailed-data?productId=${params.productId}`,
    productBan: `${devHost}/admin/product/toggle-ban`,
    allAdmin: `${devHost}/admin/admin/all`,
    userRegistrationTransactionUrlUpdated: `${devHost}/admin/transaction/charges/user-registration-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrlUpdated: `${devHost}/admin/transaction/charges/product-listing-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryLocationList: `${devHost}/services/delivery/village/all`,
    updateDeliveryCharge: `${devHost}/services/delivery/charge/update`,
    adminList: `${devHost}/admin/admin/all`,
    addAdmin: `${devHost}/admin/add`,
    deleteAdmin: `${devHost}/admin/delete`,
    editAdminInfo: `${devHost}/admin/change-info`,
    editAdminPassword: `${devHost}/admin/change-password`,
    getCategoryById: `${devHost}/product/category/main/${params.id}`,
    getVillageById: `${devHost}/services/delivery/get-by-id?id=${params.id}`,
    productModificationDataUrl: `${devHost}/admin/product/modification/list_data?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    productListingDataUrl: `${devHost}/admin/product/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    allOrderListUrl: `${devHost}/admin/all/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}&searchTerm=${params.searchTerm}`,
    categoryDelete: `${devHost}/category/delete_category`,
    subCategoryDelete: `${devHost}/category/delete_sub_category`,
    upload_banner: `${devHost}/admin/upload_banner`,
    get_banners: `${devHost}/admin/get_banners`,
    delete_banner: `${devHost}/admin/delete_banner`,
    getNotifications: `${devHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    markasread: `${devHost}/notification/mark-all-as-read`
  };

  /**
   * @var urlsQA for testing urls
   */
  let urlsQA = {
    loginUrl: `${qaHost}/user/login`,
    userDetailsUrl: `${qaHost}/user`,
    userAll: `${qaHost}/admin/user/all?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userApproval: `${qaHost}/admin/user/toggle/approve`,
    userApprovalList: `${qaHost}/admin/user/list/approval?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userBlock: `${qaHost}/admin/user/toggle/block`,
    userViewUrl: `${qaHost}/admin/user/profile/detailed-view?userId=${params.id}`,
    userReports: `${qaHost}/admin/user/reported/list?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    createCategoryUrl: `${qaHost}/product/category/create`,
    categoryListingUrl: `${qaHost}/product/category/main/get`,
    subcategoryListingUrl: `${qaHost}/product/category/sub/get?parentId=${params.categoryId}`,
    updateCategoryUrl: `${qaHost}/product/category/sub/update`,
    productListingUrl: `${qaHost}/admin/product/list?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    productApprovalUrl: `${qaHost}/admin/product/toggle-approval`,
    productModificationUrl: `${qaHost}/admin/product/modification/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    interestListUrl: `${qaHost}/admin/product/interest/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&type=${params.type}`,
    bidListUrl: `${qaHost}/admin/product/bid/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}`,
    orderListUrl: `${qaHost}/admin/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}`,
    transactionListUrl: `${qaHost}/admin/transaction/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    approveOrRejectOrderUrl: `${qaHost}/admin/order/toggle-approval`,
    updateShipmentStatus: `${qaHost}/shipment/create-status`,
    getShipmentStatus: `${qaHost}/shipment/get-status?perPage=${params.perPage}&page=${params.page}&orderId=${params.orderId}`,
    acceptCashUrl: `${qaHost}/admin/transaction/accept-cash/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}`,
    getAllConfig: `${qaHost}/admin/app-config/get-all-config`,
    setConfig: `${qaHost}/admin/app-config/set-config`,
    approveTransaction: `${qaHost}/admin/transaction/toggle-approve`,
    pickupListUrl: `${qaHost}/admin/order/pickup/list`,
    deliveryListUrl: `${qaHost}/admin/order/delivery/list`,
    deliveryChargeTransactionUrlUpdated: `${qaHost}/admin/transaction/charges/delivery-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    settlementStatusUpdateUrl: `${qaHost}/admin/transaction/update-settlement-status`,
    approveOrRejectModificationUrl: `${qaHost}/admin/product/toggle-modification-approval`,
    userRegistrationTransactionUrl: `${qaHost}/admin/transaction/charges/user-registration?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrl: `${qaHost}/admin/transaction/charges/product-listing?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryChargeTransactionUrl: `${qaHost}/admin/transaction/charges/delivery?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    userDetailedListUrl: `${qaHost}/admin/user/detailed/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}`,
    userDetailedViewUrl: `${qaHost}/admin/user/detailed-data?userId=${params.userId}`,
    productDetailedViewUrl: `${qaHost}/admin/product/detailed-data?productId=${params.productId}`,
    productBan: `${qaHost}/admin/product/toggle-ban`,
    allAdmin: `${qaHost}/admin/admin/all`,
    transactionListUrlUpdated: `${qaHost}/admin/transaction/list-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    userRegistrationTransactionUrlUpdated: `${qaHost}/admin/transaction/charges/user-registration-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrlUpdated: `${qaHost}/admin/transaction/charges/product-listing-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryLocationList: `${qaHost}/services/delivery/village/all`,
    updateDeliveryCharge: `${qaHost}/services/delivery/charge/update`,
    adminList: `${qaHost}/admin/admin/all`,
    addAdmin: `${qaHost}/admin/add`,
    deleteAdmin: `${qaHost}/admin/delete`,
    editAdminInfo: `${qaHost}/admin/change-info`,
    editAdminPassword: `${qaHost}/admin/change-password`,
    getCategoryById: `${qaHost}/product/category/main/${params.id}`,
    getVillageById: `${qaHost}/services/delivery/get-by-id?id=${params.id}`,
    productModificationDataUrl: `${qaHost}/admin/product/modification/list_data?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    productListingDataUrl: `${qaHost}/admin/product/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    allOrderListUrl: `${qaHost}/admin/all/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}&searchTerm=${params.searchTerm}`,
    categoryDelete: `${qaHost}/category/delete_category`,
    subCategoryDelete: `${qaHost}/category/delete_sub_category`,
    upload_banner: `${qaHost}/admin/upload_banner`,
    get_banners: `${qaHost}/admin/get_banners`,
    delete_banner: `${devHost}/admin/delete_banner`,
    getNotifications: `${devHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    markasread: `${devHost}/notification/mark-all-as-read`
  };

  /**
   * @var urlsPROD for production urls
   */
  let urlsPROD = {
    loginUrl: `${prodHost}/user/login`,
    userDetailsUrl: `${prodHost}/user`,
    userAll: `${prodHost}/admin/user/all?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userApprovalList: `${prodHost}/admin/user/list/approval?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    userApproval: `${prodHost}/admin/user/toggle/approve`,
    userBlock: `${prodHost}/admin/user/toggle/block`,
    userViewUrl: `${prodHost}/admin/user/profile/detailed-view?userId=${params.id}`,
    userReports: `${prodHost}/admin/user/reported/list?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    createCategoryUrl: `${prodHost}/product/category/create`,
    categoryListingUrl: `${prodHost}/product/category/main/get`,
    subcategoryListingUrl: `${prodHost}/product/category/sub/get?parentId=${params.categoryId}`,
    updateCategoryUrl: `${prodHost}/product/category/sub/update`,
    productListingUrl: `${prodHost}/admin/product/list?filter=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    productApprovalUrl: `${prodHost}/admin/product/toggle-approval`,
    productModificationUrl: `${prodHost}/admin/product/modification/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    interestListUrl: `${prodHost}/admin/product/interest/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&type=${params.type}`,
    bidListUrl: `${prodHost}/admin/product/bid/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}`,
    orderListUrl: `${prodHost}/admin/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}`,
    transactionListUrl: `${prodHost}/admin/transaction/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    approveOrRejectOrderUrl: `${prodHost}/admin/order/toggle-approval`,
    updateShipmentStatus: `${prodHost}/shipment/create-status`,
    getShipmentStatus: `${prodHost}/shipment/get-status?perPage=${params.perPage}&page=${params.page}&orderId=${params.orderId}`,
    acceptCashUrl: `${prodHost}/admin/transaction/accept-cash/list?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}`,
    getAllConfig: `${prodHost}/admin/app-config/get-all-config`,
    setConfig: `${prodHost}/admin/app-config/set-config`,
    approveTransaction: `${prodHost}/admin/transaction/toggle-approve`,
    pickupListUrl: `${prodHost}/admin/order/pickup/list`,
    deliveryListUrl: `${prodHost}/admin/order/delivery/list`,
    settlementStatusUpdateUrl: `${prodHost}/admin/transaction/update-settlement-status`,
    approveOrRejectModificationUrl: `${prodHost}/admin/product/toggle-modification-approval`,
    userRegistrationTransactionUrl: `${prodHost}/admin/transaction/charges/user-registration?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrl: `${prodHost}/admin/transaction/charges/product-listing?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryChargeTransactionUrl: `${prodHost}/admin/transaction/charges/delivery?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    userDetailedListUrl: `${prodHost}/admin/user/detailed/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}`,
    userDetailedViewUrl: `${prodHost}/admin/user/detailed-data?userId=${params.userId}`,
    productDetailedViewUrl: `${prodHost}/admin/product/detailed-data?productId=${params.productId}`,
    productBan: `${prodHost}/admin/product/toggle-ban`,
    allAdmin: `${prodHost}/admin/admin/all`,
    deliveryChargeTransactionUrlUpdated: `${prodHost}/admin/transaction/charges/delivery-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    transactionListUrlUpdated: `${prodHost}/admin/transaction/list-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&filter=${params.filter}&from=${params.from}&to=${params.to}&extraSearchTerm=${params.extraSearchTerm}`,
    userRegistrationTransactionUrlUpdated: `${prodHost}/admin/transaction/charges/user-registration-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    productOnboardingTransactionUrlUpdated: `${prodHost}/admin/transaction/charges/product-listing-updated?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&searchTerm=${params.searchTerm}&type=${params.type}&from=${params.from}&to=${params.to}`,
    deliveryLocationList: `${prodHost}/services/delivery/village/all`,
    updateDeliveryCharge: `${prodHost}/services/delivery/charge/update`,
    adminList: `${prodHost}/admin/admin/all`,
    addAdmin: `${prodHost}/admin/add`,
    deleteAdmin: `${prodHost}/admin/delete`,
    editAdminInfo: `${prodHost}/admin/change-info`,
    editAdminPassword: `${prodHost}/admin/change-password`,
    getCategoryById: `${prodHost}/product/category/main`,
    getVillageById: `${prodHost}/services/delivery/get-by-id?id=${params.id}`,
    productModificationDataUrl: `${prodHost}/admin/product/modification/list_data?perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}`,
    productListingDataUrl: `${prodHost}/admin/product/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}&searchTerm=${params.searchTerm}&sort=${params.sort}&isPaid=${params.isPaid}&filter=${params.filter}`,
    allOrderListUrl: `${prodHost}/admin/all/order/list?perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&approvalStatus=${params.approvalStatus}&paymentMode=${params.paymentMode}&from=${params.from}&to=${params.to}&searchTerm=${params.searchTerm}`,
    categoryDelete: `${prodHost}/category/delete_category`,
    subCategoryDelete: `${prodHost}/category/delete_sub_category`,
    upload_banner: `${prodHost}/admin/upload_banner`,
    get_banners: `${devHost}/admin/get_banners`,
    delete_banner: `${devHost}/admin/delete_banner`,
    getNotifications: `${devHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    markasread: `${devHost}/notification/mark-all-as-read`
  };

  return urlsDEV[key];
};

export default urlConfig;
